import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import Footer from "./pages/Footer";
import { Box, Typography, useMediaQuery } from "@mui/material";
import ocd from "./Images/ocd.mp4";

function App() {
  const matches = useMediaQuery("(max-width:950px)");
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Box
        sx={{
          position: "relative",
        }}
      >
        <video
          autoPlay
          crossOrigin="anonymous"
          loop
          muted
          playsInline
          preload="auto"
          role="presentation"
          tabIndex="-1"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={ocd} type="video/mp4" />
        </video>
        <Header />
        <Typography
          py={20}
          sx={{
            color: "#fff",
            fontSize: matches ? "36px" : "80px",
            fontWeight: "700",
            fontFamily: "Ubuntu",
            textAlign: "center",
            lineHeight: matches ? "40px" : "60px",
          }}
        >
          On Chain Dynamics <br />
          <span style={{ fontSize: matches ? "26px" : "40px" }}>
            Revenue Dashboard
          </span>
        </Typography>
      </Box>
      <HeroSection />
      <Footer />
    </>
  );
}

export default App;
