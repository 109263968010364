import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
// import { WagmiConfig } from "wagmi";
// import { mainnet } from "wagmi/chains";
import { ConectivityProvider } from "./utils";

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'

import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { mainnet, bsc } from 'viem/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import icon from './Images/tabicon.png'

// wallet start

// // const projectId = "cba73ada547c01c1a64d7725fb732495";
// const chains = [mainnet];

// const wagmiConfig = defaultWagmiConfig({ chains, projectId });

// createWeb3Modal({
//   wagmiConfig,
//   projectId,
//   chains,
//   themeMode: "dark",
// });



// 1. Get projectId at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_MY_PROJECT_ID;


// 2. Create wagmiConfig
const { chains, publicClient } = configureChains(
  [mainnet],
  [
    walletConnectProvider({ projectId }), 
    publicProvider(),
    // jsonRpcProvider({
    //   rpc: (chain) => ({
    //     http: `https://${chain.id}.example.com`,
    //   }),
    // }),
  ]
)

const metadata = {
  name: 'On-Chain Dynamics',
  description: 'Next-Level Trading Tools',
  url: 'https://onchaindynamics.io',
  icons: [icon]
}

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
  ],
  publicClient
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig, 
  projectId, 
  chains,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-accent': '#ce4be01a'
  }
})

// wallet end

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <ConectivityProvider>
        <App />
      </ConectivityProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
