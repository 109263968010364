import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { logo } from "../components/SmallComponents/Images";

export default function Footer() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <Box mt={5} pb={10}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={2.4}
            display="flex"
            // justifyContent={matches && "center"}
          >
            <img src={logo} height="80px" alt="" />
          </Grid>
          <Grid
            item
            xs={6}
            md={2.4}
            display="flex"
            flexDirection="column"
            alignItems={!matches && "center"}
          >
            <Typography
              sx={{
                fontFamily: "",
                fontSize: "14px",
                color: "#E6A9FE",
              }}
            >
              Links
            </Typography>
            <a
              href="https://www.onchaindynamics.io/"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                pl={!matches && 4}
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Sniper Bot
              </Typography>
            </a>
            <a
              href="https://www.onchaindynamics.io/"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                pl={!matches && 6}
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Telegram Bot
              </Typography>
            </a>
            <a
              href="https://www.onchaindynamics.io/"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                pl={!matches && 4}
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Flash Bots
              </Typography>
            </a>
            <a
              href="https://www.onchaindynamics.io/"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                pl={!matches && 6}
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Copy Traders
              </Typography>
            </a>
          </Grid>
          <Grid
            item
            xs={6}
            md={2.4}
            display="flex"
            flexDirection="column"
            alignItems={!matches && "center"}
          >
            <Typography
              sx={{
                fontFamily: "",
                fontSize: "14px",
                color: "#E6A9FE",
              }}
            >
              Socials
            </Typography>
            <a
              href="https://twitter.com/OnChainDynamics"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Twitter
              </Typography>
            </a>
            <a
              href="https://t.me/OCDPortal"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                pl={!matches && 2}
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Telegram
              </Typography>
            </a>
            <a
              href="mailto:contact@onchaindynamics.io"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
            <Typography
              pl={!matches && 3.3}
              sx={{
                fontFamily: "",
                fontSize: "14px",
                color: "#fff",
                cursor: "pointer",
                "&:hover": {
                  color: "gray",
                },
              }}
            >
              Contact Us
            </Typography>
            </a>
          </Grid>
          <Grid
            item
            xs={6}
            md={2.4}
            display="flex"
            flexDirection="column"
            alignItems={!matches && "center"}
          >
            <Typography
              sx={{
                fontFamily: "",
                fontSize: "14px",
                color: "#E6A9FE",
              }}
            >
              Resources
            </Typography>
            <a
              href="https://www.onchaindynamics.io/OCD_LitepaperV2.pdf"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                pr={!matches && 4}
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Docs
              </Typography>
            </a>
            <a
              href="https://www.dextools.io/app/en/ether/pair-explorer/0x9e7065ce7e029d6a6d4936091547921a8008c4d3"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                pl={!matches && 1.5}
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Token Chart
              </Typography>
            </a>
            <a
              href="https://app.uniswap.org/swap?outputCurrency=0x017E9Db34fC69Af0dC7c7b4b33511226971cDdc7&inputCurrency=eth"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                sx={{
                  fontFamily: "",
                  fontSize: "14px",
                  color: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Buy $OCD
              </Typography>
            </a>
          </Grid>
          <Grid item xs={6} md={2.4} display="flex" flexDirection="column">
            <Typography
              sx={{
                fontFamily: "",
                fontSize: "14px",
                color: "#E6A9FE",
              }}
            >
              Legals
            </Typography>
            <Typography
              sx={{
                fontFamily: "",
                fontSize: "14px",
                color: "#fff",
                cursor: "pointer",
                "&:hover": {
                  color: "gray",
                },
              }}
            >
              Terms & Conditions
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontFamily: "",
                fontSize: "9px",
                color: "#cccccc",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              @ 2023 On-Chain-Dynamics <br />
              partners@onchaindynamics.io
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
